import React, { useState } from "react";
import { Select, DatePicker } from "antd";
import { BiGlobe } from "react-icons/bi";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { MenuButton, MenuList, Menu, MenuItem } from "@chakra-ui/react";
import CustomScrollbar from "./CustomScrollBar";
import { useSelector } from "react-redux";

const FilterComponent = ({
  formData,
  setformData,
  owners,
  removeField,
  addField,
  values,
  visibleFields,
  setVisibleFields,
}) => {
  const Sessions = useSelector((state) => state.user?.sessionList);
  const options = [
    { value: "equal_to", label: "is equal to" },
    { value: "inequal_to", label: "does not equal to" },
    { value: "greater_than", label: "is greater than" },
    { value: "less_than", label: "is less than" },
  ];

  const comparisonOptions = [
    { value: "before", label: "is before" },
    { value: "after", label: "is after" },
    { value: "between", label: "is between" },
    { value: "never", label: "never" },
  ];
  const [showSecondCalendar, setShowSecondCalendar] = useState(false);

  const handleComparisonChange = (value) => {
    setformData((prevData) => ({
      ...prevData,
      lastUpdated: {
        ...prevData.lastUpdated,
        comparison: value,
      },
    }));
    if (value === "between") {
      setShowSecondCalendar(true);
    } else {
      setShowSecondCalendar(false);
    }
  };

  const handleFirstCalendarChange = (date) => {
    setformData((prevData) => ({
      ...prevData,
      lastUpdated: {
        ...prevData.lastUpdated,
        value1: date,
      },
    }));
  };

  const handleSecondCalendarChange = (date) => {
    setformData((prevData) => ({
      ...prevData,
      lastUpdated: {
        ...prevData.lastUpdated,
        value2: date,
      },
    }));
  };

  const showCal = formData?.lastUpdated?.comparison === "never";

  return (
    <div
      className="text-black absolute bg-white z-50 top-0 right-0  rounded-md p-4 pl-12"
      // onClick={handleModalClick}
      style={{
        width: "600px",
        boxShadow: "0px 4px 13px 0px #D5E2F5",
      }}
    >
      <div className="overflow-y-auto" style={{ maxHeight: "250px" }}>
        {visibleFields.includes("Goal Name") && (
          <div className="grid items-center grid-cols-7 py-4 below-div gap-y-4 gap-x-4">
            <label
              className="col-span-2 font-semibold text-gray-600"
              style={{ color: "#0A4464" }}
            >
              Goal Name
            </label>
            <div className="col-span-4 ">
              <div className="flex items-center gap-x-2">
                <input
                  className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg"
                  type="text"
                  name="goal_name"
                  id="goal_name"
                  onChange={(e) => {
                    const updatedValue = e.target.value;
                    setformData({ ...formData, goal_name: updatedValue });
                  }}
                  value={formData?.goal_name || ""}
                  maxLength={70}
                  placeholder="Enter a Name"
                />
              </div>
            </div>
            <div className="col-span-1">
              <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => removeField("Goal Name", "goal_name")}
              >
                <path
                  d="M2.61537 16.5C2.16794 16.5 1.78685 16.3426 1.4721 16.0279C1.15737 15.7131 1 15.332 1 14.8846V2.49996H0V1.49996H4V0.730713H10V1.49996H14V2.49996H13V14.8846C13 15.3448 12.8458 15.7291 12.5375 16.0375C12.2292 16.3458 11.8449 16.5 11.3846 16.5H2.61537ZM12 2.49996H2V14.8846C2 15.0641 2.05769 15.2115 2.17308 15.3269C2.28846 15.4423 2.43589 15.5 2.61537 15.5H11.3846C11.5385 15.5 11.6795 15.4359 11.8077 15.3077C11.9359 15.1794 12 15.0384 12 14.8846V2.49996ZM4.80768 13.5H5.8077V4.49996H4.80768V13.5ZM8.1923 13.5H9.19232V4.49996H8.1923V13.5Z"
                  fill="#435F6F"
                />
              </svg>
            </div>
          </div>
        )}
        {visibleFields.includes("Goal Owners") && (
          <div className="grid items-center grid-cols-7 py-4 below-div gap-y-4 gap-x-4">
            <label
              className="col-span-2 font-semibold text-gray-600"
              style={{ color: "#0A4464" }}
            >
              Goal Owners
            </label>
            <div className="col-span-4 ">
              <Select
                name="=owner"
                id="owner"
                onChange={(val) => {
                  setformData({ ...formData, goal_owner: val });
                }}
                value={formData?.goal_owner}
                mode="multiple"
                required
                className="w-full "
                placeholder="Select User Or Team"
              >
                {owners?.map((owner, index) => {
                  return (
                    <Select.Option
                      value={owner?.id + " " + owner?.type}
                      key={index}
                    >
                      <div className="flex items-center gap-x-2">
                        {owner?.type === "user" ? (
                          <div
                            className="w-5 h-5 border text-sm border-black rounded-full flex items-center justify-center text-white relative"
                            style={{
                              backgroundColor: owner?.profile_image
                                ? "initial"
                                : getAvatarColor(owner?.name),
                            }}
                            size={18}
                          >
                            {owner?.profile_image ? (
                              <img
                                src={owner?.profile_image}
                                alt="Profile"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                  display: "block",
                                }}
                              />
                            ) : (
                              getInitials(owner.name)
                            )}
                          </div>
                        ) : (
                          <span className="p-1 bg-gray-600 rounded-full">
                            <BiGlobe className="text-gray-300 text-xs" />
                          </span>
                        )}
                        <span>{owner.name}</span>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div className="col-span-1">
              <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => removeField("Goal Owners", "goal_owner")}
              >
                <path
                  d="M2.61537 16.5C2.16794 16.5 1.78685 16.3426 1.4721 16.0279C1.15737 15.7131 1 15.332 1 14.8846V2.49996H0V1.49996H4V0.730713H10V1.49996H14V2.49996H13V14.8846C13 15.3448 12.8458 15.7291 12.5375 16.0375C12.2292 16.3458 11.8449 16.5 11.3846 16.5H2.61537ZM12 2.49996H2V14.8846C2 15.0641 2.05769 15.2115 2.17308 15.3269C2.28846 15.4423 2.43589 15.5 2.61537 15.5H11.3846C11.5385 15.5 11.6795 15.4359 11.8077 15.3077C11.9359 15.1794 12 15.0384 12 14.8846V2.49996ZM4.80768 13.5H5.8077V4.49996H4.80768V13.5ZM8.1923 13.5H9.19232V4.49996H8.1923V13.5Z"
                  fill="#435F6F"
                />
              </svg>
            </div>
          </div>
        )}
        {visibleFields.includes("Last Updated") && (
          <div className="grid items-center grid-cols-7 py-4 below-div gap-y-4 gap-x-4">
            <label
              className="col-span-2 font-semibold text-gray-600"
              style={{ color: "#0A4464" }}
            >
              Last updated
            </label>
            <div className="col-span-4 h-full items-end">
              <div className="flex h-full items-end justify-between gap-x-2">
                <Select
                  defaultValue="before"
                  className="w-full mr-2 h-full"
                  onChange={handleComparisonChange}
                  value={formData.lastUpdated.comparison}
                >
                  {comparisonOptions?.map((option, index) => (
                    <Select.Option key={index} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
                {!showCal && (
                  <DatePicker
                    selected={formData.lastUpdated.value}
                    onChange={handleFirstCalendarChange}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select A Date"
                    className="w-full h-full"
                  />
                )}
                {showSecondCalendar &&
                  formData?.lastUpdated?.comparison === "between" && (
                    <DatePicker
                      selected={null}
                      onChange={handleSecondCalendarChange}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="Select A Date"
                      className="w-full h-full"
                    />
                  )}
              </div>
            </div>
            <div className="col-span-1">
              <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => removeField("Last Updated", "lastUpdated")}
              >
                <path
                  d="M2.61537 16.5C2.16794 16.5 1.78685 16.3426 1.4721 16.0279C1.15737 15.7131 1 15.332 1 14.8846V2.49996H0V1.49996H4V0.730713H10V1.49996H14V2.49996H13V14.8846C13 15.3448 12.8458 15.7291 12.5375 16.0375C12.2292 16.3458 11.8449 16.5 11.3846 16.5H2.61537ZM12 2.49996H2V14.8846C2 15.0641 2.05769 15.2115 2.17308 15.3269C2.28846 15.4423 2.43589 15.5 2.61537 15.5H11.3846C11.5385 15.5 11.6795 15.4359 11.8077 15.3077C11.9359 15.1794 12 15.0384 12 14.8846V2.49996ZM4.80768 13.5H5.8077V4.49996H4.80768V13.5ZM8.1923 13.5H9.19232V4.49996H8.1923V13.5Z"
                  fill="#435F6F"
                />
              </svg>
            </div>
          </div>
        )}
        {visibleFields.includes("Objective Name") && (
          <div className="grid items-center grid-cols-7 py-4 below-div gap-y-4 gap-x-4">
            <label
              className="col-span-2 font-semibold text-gray-600"
              style={{ color: "#0A4464" }}
            >
              Objective Name
            </label>
            <div className="col-span-4 ">
              <div className="flex items-center gap-x-2">
                <input
                  className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg"
                  type="text"
                  name="okr_name"
                  id="okr_name"
                  onChange={(e) => {
                    const updatedValue = e.target.value;
                    setformData({ ...formData, okr_name: updatedValue });
                  }}
                  value={formData?.okr_name || ""}
                  maxLength={70}
                  placeholder="Enter a Name"
                />
              </div>
            </div>
            <div className="col-span-1">
              <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => removeField("Objective Name", "okr_name")}
              >
                <path
                  d="M2.61537 16.5C2.16794 16.5 1.78685 16.3426 1.4721 16.0279C1.15737 15.7131 1 15.332 1 14.8846V2.49996H0V1.49996H4V0.730713H10V1.49996H14V2.49996H13V14.8846C13 15.3448 12.8458 15.7291 12.5375 16.0375C12.2292 16.3458 11.8449 16.5 11.3846 16.5H2.61537ZM12 2.49996H2V14.8846C2 15.0641 2.05769 15.2115 2.17308 15.3269C2.28846 15.4423 2.43589 15.5 2.61537 15.5H11.3846C11.5385 15.5 11.6795 15.4359 11.8077 15.3077C11.9359 15.1794 12 15.0384 12 14.8846V2.49996ZM4.80768 13.5H5.8077V4.49996H4.80768V13.5ZM8.1923 13.5H9.19232V4.49996H8.1923V13.5Z"
                  fill="#435F6F"
                />
              </svg>
            </div>
          </div>
        )}
        {visibleFields.includes("Objective Owners") && (
          <div className="grid items-center grid-cols-7 py-4 below-div gap-y-4 gap-x-4">
            <label
              className="col-span-2 font-semibold text-gray-600"
              style={{ color: "#0A4464" }}
            >
              Objective Owners
            </label>
            <div className="col-span-4 ">
              <Select
                name="=owner"
                id="owner"
                onChange={(val) => {
                  setformData({ ...formData, okr_owner: val });
                }}
                value={formData.okr_owner}
                mode="multiple"
                required
                className="w-full "
                placeholder="Select User Or Team"
              >
                {owners?.map((owner, index) => {
                  return (
                    <Select.Option
                      value={owner?.id + " " + owner?.type}
                      key={index}
                    >
                      <div className="flex items-center gap-x-2">
                        {owner?.type === "user" ? (
                          <div
                            className="w-5 h-5 border text-sm border-black rounded-full flex items-center justify-center text-white relative"
                            style={{
                              backgroundColor: owner?.profile_image
                                ? "initial"
                                : getAvatarColor(owner?.name),
                            }}
                            size={18}
                          >
                            {owner?.profile_image ? (
                              <img
                                src={owner?.profile_image}
                                alt="Profile"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                  display: "block",
                                }}
                              />
                            ) : (
                              getInitials(owner.name)
                            )}
                          </div>
                        ) : (
                          <span className="p-1 bg-gray-600 rounded-full">
                            <BiGlobe className="text-gray-300 text-xs" />
                          </span>
                        )}
                        <span>{owner.name}</span>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div className="col-span-1">
              <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => removeField("Objective Owners", "okr_owner")}
              >
                <path
                  d="M2.61537 16.5C2.16794 16.5 1.78685 16.3426 1.4721 16.0279C1.15737 15.7131 1 15.332 1 14.8846V2.49996H0V1.49996H4V0.730713H10V1.49996H14V2.49996H13V14.8846C13 15.3448 12.8458 15.7291 12.5375 16.0375C12.2292 16.3458 11.8449 16.5 11.3846 16.5H2.61537ZM12 2.49996H2V14.8846C2 15.0641 2.05769 15.2115 2.17308 15.3269C2.28846 15.4423 2.43589 15.5 2.61537 15.5H11.3846C11.5385 15.5 11.6795 15.4359 11.8077 15.3077C11.9359 15.1794 12 15.0384 12 14.8846V2.49996ZM4.80768 13.5H5.8077V4.49996H4.80768V13.5ZM8.1923 13.5H9.19232V4.49996H8.1923V13.5Z"
                  fill="#435F6F"
                />
              </svg>
            </div>
          </div>
        )}

        {visibleFields.includes("Progress") && (
          <div className="grid items-center grid-cols-7 py-4 below-div gap-y-4 gap-x-4">
            <label
              className="col-span-2 font-semibold text-gray-600"
              style={{ color: "#0A4464" }}
            >
              Progess
            </label>
            <div className="col-span-4 items-end h-full">
              <div className="flex h-full w-full  items-end justify-between gap-x-2">
                <div className="w-full h-full ">
                <Select
                  defaultValue="is equal to"
                  className="w-full mr-2  min-h-full items-center"
                  onChange={(newValue) => {
                    if (newValue) {
                      setformData((prevData) => ({
                        ...prevData,
                        progress: {
                          ...prevData.progress,
                          comparison: newValue,
                        },
                      }));
                    }
                  }}
                >
                  {options.map((option, index) => (
                    <Select.Option key={index} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
                </div>
                <input
                  className="w-full h-full col-span-1 px-2 align-middle text-sm border border-gray-300 rounded-md"
                  type="number"
                  name="progress"
                  id="progress"
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value);
                    setformData((prevData) => ({
                      ...prevData,
                      progress: {
                        ...prevData.progress,
                        value: newValue,
                      },
                    }));
                  }}
                  value={formData.progress.value}
                  maxLength={70}
                  placeholder="Enter a Value"
                />
              </div>
            </div>

            <div
              className="col-span-1"
              onClick={() => removeField("Progress", "progress")}
            >
              <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.61537 16.5C2.16794 16.5 1.78685 16.3426 1.4721 16.0279C1.15737 15.7131 1 15.332 1 14.8846V2.49996H0V1.49996H4V0.730713H10V1.49996H14V2.49996H13V14.8846C13 15.3448 12.8458 15.7291 12.5375 16.0375C12.2292 16.3458 11.8449 16.5 11.3846 16.5H2.61537ZM12 2.49996H2V14.8846C2 15.0641 2.05769 15.2115 2.17308 15.3269C2.28846 15.4423 2.43589 15.5 2.61537 15.5H11.3846C11.5385 15.5 11.6795 15.4359 11.8077 15.3077C11.9359 15.1794 12 15.0384 12 14.8846V2.49996ZM4.80768 13.5H5.8077V4.49996H4.80768V13.5ZM8.1923 13.5H9.19232V4.49996H8.1923V13.5Z"
                  fill="#435F6F"
                />
              </svg>
            </div>
          </div>
        )}

        <Menu>
          <MenuButton
            className="text-xs"
            px={4}
            py={2}
            transition="all 0.2s"
            borderRadius="2xl"
            borderWidth="1px"
            borderColor={"#287199"}
            _focus={{ boxShadow: "outline" }}
          >
            Add Filter
          </MenuButton>
          <MenuList>
            {values?.map((value, index) => (
              <MenuItem
                className="text-sm"
                key={index}
                onClick={() => addField(value)}
              >
                {value}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};

export default FilterComponent;
