import React, { useState, useEffect } from "react";
import { Select, Modal, Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { baseURL } from "../../utils/config";
import { useSelector } from "react-redux";
import { api } from "../../components/global/api";
import { Button, Spinner } from "@chakra-ui/react";

const EditEmployeeModal = ({ data, handleCancel, fetchData, modalOpen }) => {
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;
  const [formData, setFormData] = useState({
    assign_role: "",
    report_to: "",
  });
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [reportError, setReportError] = useState(false);
  const [assignRole, setAssignRole] = useState([]);
  const [reportRoles, setReportRoles] = useState([]);
  const [roleAdmin, setRoleAdmin] = useState(false);

  console.log("dhdhd", currentUser?.employees[user?.currentCompany]);

  useEffect(() => {
    if (data) {
      setFormData({
        report_to: data?.report_to || "",
        assign_role: data?.role || "",
      });
      fetchReportRoles(data?.role, data);
    }
  }, [data]);

  const fetchRole = async () => {
    try {
      const response = await api.get(baseURL + "/role/", {
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
      });
      setAssignRole(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRole();
  }, []);

  const fetchReportRoles = async (roleId, data) => {
    console.log(data);
    try {
      const res = await api.post(baseURL + "/report-to/", {
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        employee_id: data?.employee_id,
      });
      setReportRoles(res?.data);
    } catch (error) {
      console.error("Error fetching report roles:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);

    // Validate required fields
    let hasError = false;
    if (!formData?.assign_role) {
      setRoleError(true);
      hasError = true;
    }
    if (!formData?.report_to) {
      setReportError(true);
      hasError = true;
    }
    if (hasError) return;

    try {
      setLoadingInvite(true);

      const res = await api.put(baseURL + "/report-to/", {
        role_id: formData?.assign_role,
        report_to: formData?.report_to,
        employee_id: data?.employee_id,
      });
      if (res?.status === 200) {
        toast.success("Employee Details Updated Successfully");
      } else {
        toast.error(res?.message || "Please Try Again");
      }
      fetchData(); // Fetch updated data
      handleCancel(); // Close modal
      setFormData({ assign_role: "", report_to: "" });
    } catch (error) {
      console.error("Error updating employee:", error);
      const status = error?.response?.status;
      if (status === 404) {
        toast.error("Employee Already Exists in the Company");
      } else {
        toast.error("Failed to Update Employee. Please Try Again.");
      }
    } finally {
      setLoadingInvite(false);
    }
  };

  console.log("assign_role", assignRole?.[formData?.assign_role]);

  return (
    <Modal
      title="Edit Employee"
      open={modalOpen}
      onCancel={handleCancel}
      width={650}
      footer={null}
    >
      <form className="p-6" onSubmit={handleSubmit}>
        <h1 className="mb-4 text-3xl font-bold text-black">Employee Details</h1>
        <hr className="mb-4" />

        <div className="grid grid-cols-6 gap-4">
          {/* Email Field */}
          <label className="col-span-2 font-semibold text-gray-600">
            Email*
          </label>
          <div className="col-span-4">
            <input
              className="w-full p-2 border rounded-lg"
              type="email"
              value={data?.email || ""}
              disabled
            />
          </div>

          {/* Assign Role */}
          <label className="col-span-2 font-semibold text-gray-600">
            Assign Role(s)*
          </label>
          <div className="col-span-4">
            <Select
              className="w-full"
              placeholder="Select Role"
              onChange={(e) => {
                setRoleAdmin(false);
                setRoleError(false);
                setFormData({ ...formData, assign_role: e, report_to: "" });
                fetchReportRoles(e, data); // Fetch new report roles
                const selectedRole = assignRole?.find(
                  (role) => role?.role_id === e
                );
                console.log("selele", selectedRole);
                if (selectedRole?.role_name === "Admin") {
                  console.log("selele", selectedRole?.role_name);
                  setRoleAdmin(true);
                }
              }}
              value={formData.assign_role}
            >
              {assignRole?.map((role) => (
                <Select.Option key={role.role_id} value={role.role_id}>
                  {role?.role_name}
                </Select.Option>
              ))}
            </Select>
            {roleError && <p className="mt-1 text-red-500">Role is required</p>}
          </div>

          {/* Report To */}
          <label className="col-span-2 font-semibold text-gray-600">
            Report To*
          </label>
          <div className="col-span-4">
            <Select
              className="w-full"
              placeholder="Select Whom To Report To"
              onChange={(e) => {
                setReportError(false);
                const selectedRole = reportRoles?.find(
                  (role) => role.role_id === e
                );
                setFormData({ ...formData, report_to: e });
                if (selectedRole?.role_name === "Admin") {
                  setRoleAdmin(true);
                }
              }}
              value={formData.report_to}
            >
              {reportRoles?.map((role) => (
                <Select.Option
                  key={role?.employee_id}
                  value={role?.employee_id}
                  disabled={data?.employee_id === role?.employee_id}
                >
                  {role?.role_name}, {role?.name}
                </Select.Option>
              ))}
            </Select>
            {reportError && (
              <p className="mt-1 text-red-500">Report To field is required</p>
            )}
          </div>
        </div>

        <div className="flex justify-end mt-6 gap-x-4">
          <Button
            onClick={handleCancel}
            border="1px"
            borderRadius="xl"
            px={4}
            py={2}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            bg="orange.400"
            color="white"
            borderRadius="xl"
            px={4}
            py={2}
            isDisabled={loadingInvite}
            _hover={{ bg: "orange.500" }} // Optional: Add hover effect
          >
            Update
            {loadingInvite && <Spinner size="sm" ml={2} />}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EditEmployeeModal;
