import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Navroutes, StaticRoutes } from "../../utils/Navroutes";
import { useDispatch, useSelector } from "react-redux";
import { handleOpenPlanModal } from "../../stores/slices/userAuthSlice";
import { handleUpdateSession } from "../../stores/slices/okrSlice";
import CustomContactUs from "./CustomContactUs";

const ToggleIcon = ({ expanded, color }) => (
  <div style={{ color }}>
    {expanded ? (
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 15L12 10L7 15"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ) : (
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 10L12 15L17 10"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
  </div>
);

const MobileSidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location?.pathname;
  const userold = useSelector((state) => state?.user);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeRoutes, setActiveRoutes] = useState([]);
  const currentPlan = userold?.currentPlan;

  const findActiveRoutes = (routes, path) => {
    const activeRoutes = [];
    routes.forEach((route) => {
      if (
        path === route.path ||
        (route.subroutes &&
          route.subroutes.some((subroute) => path.includes(subroute.path)))
      ) {
        activeRoutes.push(route);
      }
    });
    return activeRoutes;
  };

  const sidebarRef = useRef(null);

  const toggleSidebar = () => setSidebarOpen((prev) => !prev);

  // Close sidebar if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={sidebarRef}>
      <button
        onClick={toggleSidebar}
        className="text-primary flex items-center p-2"
      >
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 6h18M3 12h18M3 18h18" stroke="black" strokeWidth="2" />
        </svg>
      </button>

      {sidebarOpen && (
        <div className="absolute top-full right-0 bg-white shadow-md rounded-md mt-2 w-64 z-50">
          <ul className="py-2">
            {[...Navroutes, ...StaticRoutes].map((route, index) => (
              <MenuItem
                key={index}
                route={route}
                activeRoutes={activeRoutes}
                setActiveRoutes={setActiveRoutes}
                dispatch={dispatch}
                pathname={pathname}
                plan={currentPlan}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const MenuItem = ({
  route,
  activeRoutes,
  setActiveRoutes,
  dispatch,
  pathname,
  plan,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleAccessCheck = () => {
    if (
      (!plan?.features?.Integration &&
        route.name === "Integrations" &&
        !(plan?.plan_name === "Free") &&
        !plan?.free_trial_status) ||
      (!plan?.features?.Strategic_insights &&
        route.name === "Insight" &&
        !(plan?.plan_name === "Free") &&
        !plan?.free_trial_status)
    ) {
      dispatch(handleOpenPlanModal());
      return false;
    }
    return true;
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleMenuItemClick = (routePath, routeName) => {
    if (routeName === "iMongu Academy") {
      setModalOpen(true);
      return;
    }
    if (!handleAccessCheck()) return;
    if (routePath === "/company/goals") {
      dispatch(handleUpdateSession());
    }
    setActiveRoutes(routePath);
    navigate(routePath);
  };

  return (
    <li>
      <div
        className="flex justify-between items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
        onClick={() =>
          route.subroutes
            ? setExpanded(!expanded)
            : handleMenuItemClick(route?.path, route?.name)
        }
      >
        <div className="flex items-center space-x-2">
          {React.cloneElement(route.icon, {
            style: {
              stroke:
                activeRoutes?.name === route?.name ? "#FFA043" : "#9398AD",
            },
          })}
          <span
            className={`text-sm ${
              activeRoutes?.name === route?.name
                ? "font-semibold text-orange-500"
                : "text-gray-700"
            }`}
          >
            {route.name}
          </span>
        </div>
        {route.subroutes && (
          <ToggleIcon
            expanded={expanded}
            color={activeRoutes?.name === route?.name ? "#FFA043" : "#767D88"}
          />
        )}
      </div>
      {expanded && route.subroutes && (
        <div className="pl-6">
          {route.subroutes.map((subroute, subIndex) => (
            <div
              key={subIndex}
              className={`flex items-center px-4 py-1 text-sm cursor-pointer hover:bg-gray-100 ${
                subroute.path === pathname
                  ? "font-semibold text-orange-500"
                  : "text-gray-600"
              }`}
              onClick={() => handleMenuItemClick(subroute.path)}
            >
              {subroute?.name}
            </div>
          ))}
        </div>
      )}
      <CustomContactUs isOpen={modalOpen} onClose={closeModal} />
    </li>
  );
};

export default MobileSidebar;
