import React from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import { Box, Heading, Text, Link } from "@chakra-ui/react";
import { AppURL } from "../../utils/config";

const CookiePolicy = () => {
  return (
    <Box className="overflow-y-auto"> 
      <CustomScrollbar>
        <Box className="mx-auto" bg="white" shadow="md" p={6} rounded="md">
          <Heading as="h2" size="lg" mb={4}>
            Cookie Policy
          </Heading>
          <Text fontSize="sm" color="gray.500" mb={4}>
            Effective Date: May 26, 2024
          </Text>
          <Text mb={4}>
            We use cookies to help improve your experience of our website at{" "}
            <Link href={AppURL} color="teal.500" isExternal>
            {AppURL} 
            </Link>
            . This cookie policy is part of iMongu Technologies Inc's privacy policy.
            It covers the use of cookies between your device and our site.
          </Text>
          <Text mb={4}>
            We also provide basic information on third-party services we may
            use, who may also use cookies as part of their service. This policy
            does not cover their cookies.
          </Text>
          <Text mb={4}>
            If you don’t wish to accept cookies from us, you should instruct
            your browser to refuse cookies from{" "}
            <Link href={AppURL} color="teal.500" isExternal>
              {AppURL}
            </Link>
            . In such a case, we may be unable to provide you with some of your
            desired content and services.
          </Text>

          <Heading  as="h3" size="md" mt={6} mb={4}>
            What Is A Cookie?
          </Heading>
          <Text mb={4}>
            A cookie is a small piece of data that a website stores on your
            device when you visit. It typically contains information about the
            website itself, a unique identifier that allows the site to
            recognise your web browser when you return, additional data that
            serves the cookie’s purpose, and the lifespan of the cookie itself.
          </Text>
          <Text mb={4}>
            Cookies are used to enable certain features (e.g., logging in), track
            site usage (e.g., analytics), store your user settings (e.g., time
            zone, notification preferences), and to personalize your content
            (e.g., advertising, language).
          </Text>

          <Heading  as="h3" size="md" mt={6} mb={4}>
            Performance Cookies
          </Heading>
          <Text mb={4}>
            Performance cookies track how you use a website during your visit.
            Typically, this information is anonymous and aggregated. They help
            companies understand visitor usage patterns, identify and diagnose
            problems, and improve website experiences. These cookies may be
            set by the website you’re visiting (first-party) or by third-party
            services. They do not collect personal information about you.
          </Text>
          <Text mb={4}>
            We use performance cookies on our site.
          </Text>

          <Heading as="h3" size="md" mt={6} mb={4}>
            How Can You Control Our Website's Use of Cookies?
          </Heading>
          <Text mb={4}>
            You have the right to decide whether to accept or reject cookies on
            our Website. You can manage your cookie preferences in our Cookie
            Consent Manager. The Cookie Consent Manager allows you to select
            which categories of cookies you accept or reject.
          </Text>

          <Heading as="h3" size="md" mt={6} mb={4}>
            How Often Will We Update This Cookie Policy?
          </Heading>
          <Text mb={4}>
            We may update this Cookie Policy from time to time in order to
            reflect any changes to the cookies and related technologies we use,
            or for other operational, legal, or regulatory reasons.
          </Text>

          <Heading as="h3" size="md" mt={6} mb={4}>
            Where Can You Obtain Further Information?
          </Heading>
          <Text mb={4}>
            For any questions or concerns regarding our Cookie Policy, you may
            contact us:
          </Text>
          <Text>
            Customer Service
            <br />
            <Link href="mailto:info@imongu.com" color="teal.500">
              info@imongu.com
            </Link>
          </Text>
        </Box>
      </CustomScrollbar>
    </Box>
  );
};

export default CookiePolicy;
