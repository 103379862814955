import React, { useEffect, useRef, useState } from "react";
import { ChakraProvider, Box, Text, Flex } from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { format, parseISO } from "date-fns";
import { baseURL } from "../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { BiGlobe } from "react-icons/bi";
import { getAvatarColor, getInitials } from "../utils/helpers";
import { Tooltip, Avatar } from "antd";
import { handleOpenKeyresultDrawer } from "../stores/slices/okrSlice";
import { api } from "./global/api";
import dayjs from "dayjs";
import EastIcon from "@mui/icons-material/East";
import { Link } from "react-router-dom";

const CalendarComponent = () => {
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const today = dayjs().format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState(today);
  const user = useSelector((state) => state?.user);
  const roleAccess = user?.roleAccess;
  const containerRef = useRef(null);
  const currentMonthYear = dayjs().format("MMMM, YYYY");

  const fetchData = async () => {
    const currentCompany = user?.currentUser?.employees[user?.currentCompany];
    try {
      const response = await api.get(baseURL + `/key_results/`, {
        user_id: currentCompany?.user_id,
        company_id: currentCompany?.company_id,
      });
      const deadlines = response.data.upcoming_or_missed_deadline?.map(
        (item) => item?.deadline
      );
      const uniqueDates = [
        ...new Set(
          deadlines?.map((date) => parseISO(date)?.toISOString()?.split("T")[0])
        ),
      ];
      const sortedDates = uniqueDates.sort((a, b) => new Date(a) - new Date(b));
      setApiData(response.data.upcoming_or_missed_deadline);
      setDaysOfWeek(sortedDates);

      // Immediately filter data for today's date
      const filteredItems = response.data.upcoming_or_missed_deadline.find(
        (item) => item.deadline === today
      );
      setFilteredData(filteredItems ? filteredItems.key_results : []);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (containerRef.current && daysOfWeek.length > 0) {
      // const selectedIndex = 6;
      const selectedIndex = daysOfWeek.indexOf(selectedDate);

      if (selectedIndex < daysOfWeek.length) {
        const selectedElement = containerRef.current.children[selectedIndex];
        if (selectedElement) {
          const container = containerRef.current;

          // Calculate the left position of the selected element
          const selectedElementLeft = selectedElement.offsetLeft; // Position from the left
          const selectedElementWidth = selectedElement.offsetWidth; // Width of the selected element
          const containerWidth = container.clientWidth; // Width of the container

          // Calculate the scroll position to center the selected element
          const targetScrollLeft =
            selectedElementLeft / 2 -
            (containerWidth + selectedElementWidth) / 2;

          // Ensure we don't scroll beyond the bounds of the container
          const maxScrollLeft = container.scrollWidth - containerWidth; // Maximum scrollable width
          const clampedScrollLeft = Math.max(
            0,
            Math.min(maxScrollLeft, targetScrollLeft)
          ); // Clamp scroll value

          // Scroll to the calculated position
          container.scrollTo({
            left: clampedScrollLeft,
            behavior: "smooth",
          });
        }
      }
    }
  }, [daysOfWeek]);

  const handleDayClick = (day) => {
    const formattedDate = format(day, "yyyy-MM-dd");
    const filteredItems = apiData.find(
      (item) => item.deadline === formattedDate
    );
    setFilteredData(filteredItems ? filteredItems.key_results : []);
    setSelectedDate(formattedDate);
  };

  const handleKey = (item) => {
    if (roleAccess?.["Key-Results"]?.View) {
      dispatch(handleOpenKeyresultDrawer(item?.key_id));
    }
  };

  return (
    <ChakraProvider>
      <Box className="p-2">
        <Text className="text-lg font-semibold">Upcoming/Missed Deadlines</Text>
        <Text className="text-gray-500 mb-2 text-sm">{currentMonthYear}</Text>
        <div className="overflow-x-auto overflow-y-hidden">
          <Flex
            style={{
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              width: "100%",
            }}
            ref={containerRef}
            className="overflow-x-auto space-x-4 mb-2 overflow-y-hidden box-content"
          >
            {daysOfWeek?.map((day, index) => (
              <Box
                key={index}
                className={`flex flex-col items-center cursor-pointer ${
                  selectedDate === day ? "bg-blue-100 text-blue-600" : ""
                } rounded-lg p-2`}
                onClick={() => handleDayClick(parseISO(day))}
              >
                <Text>{format(parseISO(day), "E")}</Text>
                <Text>{format(parseISO(day), "d")}</Text>
              </Box>
            ))}
          </Flex>
        </div>
        <Box className="space-y-4 mt-4">
          {filteredData.length === 0 ? (
            <Text className="text-gray-500 text-center">
              No Deadlines For This Day.
            </Text>
          ) : (
            filteredData?.slice(0, 2)?.map((item, index) => (
              <Box
                key={index}
                className={`rounded-xl mb-4 ${
                  today > selectedDate
                    ? "bg-red-50 border-[rgba(254, 100, 97, 0.1)]"
                    : "bg-green-50 border-[rgba(43, 153, 84, 0.09)]"
                } border`}
              >
                <Flex className="items-center space-x-2 p-2">
                  <button>
                    <img
                      src="/assets/images/keyresults.svg"
                      alt="Key Result Icon"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </button>
                  <Flex className="flex-col justify-between">
                    <Text className="text-sm font-semibold text-black">
                      {item?.title}
                    </Text>
                    <Text className="text-sm text-gray-500">
                      {item?.owners?.length} Team Members
                    </Text>
                  </Flex>
                </Flex>

                <Flex className="justify-between">
                  <div className="flex gap-x-2 items-center avatar-group-custom ml-4">
                    <Avatar.Group
                      maxCount={2}
                      size={"small"}
                      maxPopoverTrigger="click"
                      maxStyle={{
                        color: "#f56a00",
                        backgroundColor: "#fde3cf",
                        display: "flex",
                        flexDirection: "column !important",
                      }}
                    >
                      {item?.owners?.map((owner, index) =>
                        item?.owners.length === 1 ? (
                          <Tooltip
                            key={index}
                            title={
                              owner?.type === "user"
                                ? owner?.username
                                : owner?.name
                            }
                          >
                            {owner?.type === "user" ? (
                              <div
                                key={index}
                                className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                                style={{
                                  backgroundColor: owner?.profile_image
                                    ? "initial"
                                    : getAvatarColor(owner?.name),
                                }}
                              >
                                {owner?.profile_image ? (
                                  <img
                                    src={owner?.profile_image}
                                    alt="Profile"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                      display: "block",
                                    }}
                                  />
                                ) : (
                                  getInitials(owner?.name)
                                )}
                              </div>
                            ) : (
                              <span
                                key={index}
                                className="p-1 bg-gray-600 rounded-full"
                              >
                                <BiGlobe className="text-gray-300 text-xs" />
                              </span>
                            )}
                          </Tooltip>
                        ) : owner?.type === "user" ? (
                          <Tooltip key={index} title={owner?.name}>
                            <div
                              className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                              style={{
                                backgroundColor: owner?.profile_image
                                  ? "initial"
                                  : getAvatarColor(owner?.name),
                              }}
                            >
                              {owner?.profile_image ? (
                                <img
                                  src={owner?.profile_image}
                                  alt="Profile"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    display: "block",
                                  }}
                                />
                              ) : (
                                getInitials(owner?.name)
                              )}
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip key={index} title={owner?.name}>
                            <span className="p-1 bg-gray-600 rounded-full">
                              <BiGlobe className="text-gray-300 text-xs" />
                            </span>
                          </Tooltip>
                        )
                      )}
                    </Avatar.Group>
                  </div>
                  <button
                    onClick={() => handleKey(item)}
                    className={`${
                      today > selectedDate
                        ? "bg-[#FE6461] hover:bg-red-500"
                        : "bg-blue-500 hover:bg-blue-600"
                    } w-1/6 text-white flex justify-center rounded-tl-xl rounded-br-xl items-center group transition-all duration-300`}
                  >
                    <FiChevronRight
                      className="transform transition-transform duration-300 group-hover:translate-x-2"
                      size={30}
                    />
                  </button>
                </Flex>
              </Box>
            ))
          )}
        </Box>
        {filteredData?.length > 0 && (
          <Link to={"/company/goals"} className="flex justify-end mt-2">
            <EastIcon className="text-gray-600" />
          </Link>
        )}
      </Box>
    </ChakraProvider>
  );
};

export default CalendarComponent;
