import React, { useEffect, useRef, useState } from "react";
import { Navroutes, StaticRoutes } from "../../utils/Navroutes";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleUpdateSession } from "../../stores/slices/okrSlice";
import { handleOpenPlanModal } from "../../stores/slices/userAuthSlice";
import { baseURL } from "../../utils/config";
import { Spinner } from "@chakra-ui/react";
import { api } from "./api";
import CustomContactUs from "./CustomContactUs";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userold = useSelector((state) => state?.user);
  const currentUser = userold?.currentUser;
  const [loading, setLoading] = useState(false);
  const currentEmployee = currentUser?.employees[userold?.currentCompany];
  const currentPlan = userold?.currentPlan;
  const pathname = location?.pathname;
  const [activeRoutes, setActiveRoutes] = useState();
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const plan = userold?.currentPlan;
  const roleAccess = userold?.roleAccess;

  const shouldIncludeBilling = roleAccess?.["Billing"]?.Access;

  const upgradePlan = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("user_id", currentPlan?.user_id);
      const res = await api.post(`${baseURL}/create-customer-portal-session/`, {
        user_id: currentPlan?.user_id,
        company_id: currentEmployee?.company_id,
      });
      const data = res?.data;
      window.location.href = data?.url;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const findActiveRoutes = (routes, path) => {
    const activeRoutes = [];

    const checkActiveRoutes = (routes, path) => {
      for (let route of routes) {
        // Exact match for the route
        if (path === route.path) {
          activeRoutes.push(route);
        }

        // Check for matching subroutes
        if (route.subroutes) {
          route.subroutes.forEach((subroute) => {
            if (path === subroute.path) {
              activeRoutes.push(subroute);
            }
          });
        }
      }
    };

    checkActiveRoutes(routes, path);

    return activeRoutes.filter((route) => path === route.path);
  };

  useEffect(() => {
    const combinedRoutes = [...StaticRoutes, ...Navroutes];
    const activeRoutes = findActiveRoutes(combinedRoutes, pathname);
    setActiveRoutes(activeRoutes);
  }, [pathname]);

  return (
    <div
      className={`flex h-screen overflow-hidden sticky text-black w-80 font-lato`}
      style={{
        minwidth: "250px",
        maxwidth: "250px",
        boxShadow: "1px 0px 10.399999618530273px 0px #C9E0FF",
        overflow: "hidden",
      }}
    >
      <nav className="flex flex-col items-start justify-between h-full col-span-1 w-full ">
        <ul
          className="flex flex-col items-center justify-start w-full mt-2 h-full flex-grow"
          style={
            {
              // minHeight: "200px",
            }
          }
        >
          <li className="flex items-center justify-center w-full p-2 mb-10">
            <div className="">
              <img
                onClick={() => {
                  navigate("/home");
                }}
                src={"/assets/images/imongu2.svg"}
                className="w-26 h-26"
                alt=""
                srcSet=""
              />
            </div>
          </li>
          <div
            className="overflow-y-auto overflow-x-hidden box-content w-full "
            style={{
              scrollbarWidth: "none",
              height: shouldIncludeBilling
                ? "calc(90vh - 300px)" // Adjusted height when billing is included
                : "calc(90vh - 160px)",
              msOverflowStyle: "none",
            }}
          >
            {Navroutes?.map((route, index) => {
              const isActive =
                Array.isArray(activeRoutes) &&
                activeRoutes.some(
                  (activeRoute) => activeRoute.path === route.path
                );
              return (
                <MenuItem
                  key={index}
                  index={index}
                  activeRoute={isActive ? route : null}
                  children={route.icon}
                  setActiveRoute={setActiveRoutes}
                  route={route}
                  dispatch={dispatch}
                  pathname={pathname}
                  plan={plan}
                  currentEmployee={currentEmployee}
                />
              );
            })}
          </div>
        </ul>
        <div
          className={`flex flex-col absolute bottom-0 w-full bg-white z-20 transition-all duration-300 ${
            shouldIncludeBilling ? "h-[280px]" : "h-[100px] justify-end"
          }`}
        >
          <div className="flex w-full px-4 items-center my-2 ">
            {shouldIncludeBilling && (
              <div
                className="font-medium w-full text-black bg-white flex p-2"
                style={{
                  border: "1px #FFF4E9 solid",
                  borderRadius: "15px",
                  backgroundColor: "#FEF5E3",
                }}
              >
                <div className="flex justify-center items-center gap-x-2 select-option-owner">
                  <span className="flex flex-col items-center justify-between gap-y-2">
                    <p
                      className="items-center px-3 font-lato text-sm"
                      style={{
                        color: "#0B0B0B",
                      }}
                    >
                      {plan?.free_trial_status
                        ? `Your Free Trial Expires In ${plan?.remaining_days} ${
                            plan?.remaining_days === 1 ? "day" : "days"
                          }.`
                        : "Upgrade Your Plan To Unlock Enhanced Features And Benefits."}
                    </p>
                    <button
                      className="font-medium flex text-sm items-center gap-x-2 bg-button hover:bg-button-hover"
                      disabled={loading}
                      style={{
                        padding: "5px 12px",
                        width: "130px",
                        borderRadius: "30px",
                        color: "#fff",
                        cursor: loading ? "not-allowed" : "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        plan?.free_trial_status
                          ? upgradePlan()
                          : navigate("/settings?tab=Billing");
                      }}
                    >
                      {loading && (
                        <Spinner
                          bg={"transparent"}
                          size="xs"
                          color="white.500"
                        />
                      )}
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1765_543)">
                          <path
                            d="M7.5001 0.643066L7.3419 0.761133C5.49326 2.15068 3.67393 3.06153 0.895996 3.52442L0.67627 3.55957V3.78223C0.67627 4.33887 0.922363 5.20899 1.32373 6.27539C1.72217 7.34473 2.28467 8.58399 2.93565 9.76758C3.58897 10.9512 4.32432 12.0791 5.09776 12.9229C5.86241 13.7695 6.66514 14.3555 7.5001 14.3555C8.33506 14.3555 9.1378 13.7695 9.90831 12.9229C10.6759 12.0791 11.4112 10.9512 12.0675 9.76758C12.7179 8.58399 13.2774 7.34473 13.6788 6.27539C14.0773 5.20899 14.3233 4.33887 14.3233 3.78223V3.55957L14.1066 3.52442C11.3351 3.06153 9.50987 2.15068 7.65831 0.761133L7.5001 0.643066ZM7.5001 1.29346C9.29014 2.61299 11.1446 3.52442 13.7814 3.99903C13.7257 4.44434 13.5177 5.21192 13.1866 6.09375C13.0665 6.41016 12.9347 6.74414 12.7853 7.08985H8.4376V5.65723L10.3126 6.12598L7.5001 2.8459L4.6876 6.12598L6.5626 5.65723V7.08985H2.21406C2.06758 6.74414 1.93311 6.41016 1.81299 6.09375C1.48486 5.21192 1.27393 4.44434 1.21826 3.99903C3.85557 3.52442 5.70713 2.61299 7.5001 1.29346Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1765_543">
                            <rect width="15" height="15" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Upgrade
                    </button>
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="z-30">
            {StaticRoutes?.map((route, index) => {
              const isActive =
                Array.isArray(activeRoutes) &&
                activeRoutes.some(
                  (activeRoute) => activeRoute.path === route.path
                );
              return (
                <MenuItem
                  key={index}
                  index={index}
                  activeRoute={isActive ? route : null}
                  children={route.icon}
                  setActiveRoute={setActiveRoutes}
                  route={route}
                  dispatch={dispatch}
                  pathname={pathname}
                  plan={plan}
                  currentEmployee={currentEmployee}
                />
              );
            })}
          </div>
        </div>
        {/* </ul> */}
      </nav>
    </div>
  );
};

export default Sidebar;

const MenuItem = ({
  index,
  activeRoute,
  children,
  setActiveRoute,
  route,
  dispatch,
  pathname,
  plan,
  currentEmployee,
}) => {
  const [expandedRoutes, setExpandedRoutes] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleAccessCheck = () => {
    if (
      (!plan?.features?.Integration &&
        route.name === "Integrations" &&
        !(plan?.plan_name === "Free") &&
        !plan?.free_trial_status) ||
      (!plan?.features?.Strategic_insights &&
        route.name === "Insight" &&
        !(plan?.plan_name === "Free") &&
        !plan?.free_trial_status)
    ) {
      if (currentEmployee?.rolename === "Admin") {
        dispatch(handleOpenPlanModal());
        return false;
      } else {
        return false;
      }
    }
    return true;
  };

  const handleIconClick = () => {
    if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
      dispatch(handleOpenPlanModal());
    } else {
      if (handleAccessCheck()) {
        setExpandedRoutes((prevState) => ({
          ...prevState,
          [route.name]: !prevState[route.name], // Toggle expanded state for this route
        }));
      }
    }
  };

  const handleMenuItemClick = (routePath, routeName) => {
    if (routeName === "iMongu Academy") {
      setModalOpen(true);
      return;
    }
    if (
      plan?.plan_name === "Free" &&
      !plan?.free_trial_status &&
      routePath !== "/home" &&
      routePath !== "/settings"
    ) {
      dispatch(handleOpenPlanModal());
    } else {
      if (handleAccessCheck()) {
        if (routePath === "/company/goals") {
          dispatch(handleUpdateSession());
        }
        setActiveRoute(routePath);
        navigate(routePath);
      }
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <li key={index} className="w-full flex flex-col">
      <div
        className={`w-full text-primary flex items-center hover:bg-[#F0F2F4] justify-between m-0.5 group/link cursor-pointer`}
        style={{
          backgroundColor: activeRoute?.name === route?.name ? "#FFF8F0" : "",
        }}
        onClick={(e) => {
          e.preventDefault();
          handleIconClick();
          handleMenuItemClick(route.path, route?.name);
        }}
      >
        <div className="flex space-x-2 items-center">
          <div
            className={`flex items-center justify-center h-6 w-1 rounded-tr-md rounded-br-md ${
              activeRoute?.name === route?.name ? "bg-[#FFA043]" : ""
            }`}
          ></div>
          <div className="py-2 px-2 flex space-x-2">
            {React.cloneElement(children, {
              style: {
                ...(children.props?.style || {}),
                stroke:
                  activeRoute?.name === route?.name ? "#FFA043" : "#9398AD",
              },
            })}
            <span
              className={`col-span-2 text-sm flex justify-center items-center ${
                activeRoute?.name === route?.name
                  ? "font-medium"
                  : "font-normal"
              }`}
              style={{
                color:
                  activeRoute?.name === route?.name ? "#FFA043" : "#9398AD",
              }}
            >
              {route.name}
            </span>
          </div>
        </div>
        {route?.subroutes?.length > 0 && (
          <div className="col-span-1 mr-2">
            {expandedRoutes[route.name] ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 15L12 10L7 15"
                  stroke={
                    activeRoute?.name === route?.name ? "#FFA043" : "#767D88"
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 10L12 15L17 10"
                  stroke="#767D88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        )}
      </div>
      {expandedRoutes[route?.name] && (
        <div>
          {route?.subroutes?.map((subroute, subIndex) => (
            <div
              key={subIndex}
              className="w-full flex flex-col justify-center items-center pt-1 pb-1 border-l ml-10 border-[#E3E3E3]"
            >
              <ul className="flex flex-col w-full gap-1 pb-1 font-lato">
                <li
                  key={subIndex}
                  className={`w-full flex pl-4 gap-x-2 text-sm rounded items-center cursor-pointer py-1 rounded-l`}
                  style={{
                    color: subroute?.path === pathname ? "#F27E0D" : "",
                    fontWeight: subroute?.path === pathname ? "400" : "500",
                  }}
                  onClick={() => {
                    if (
                      plan?.plan_name === "Free" &&
                      !plan?.free_trial_status
                    ) {
                    } else {
                      navigate(subroute.path);
                    }
                  }}
                >
                  {subroute?.path === pathname ? (
                    <div className="w-2 h-2 bg-orange-500 rounded-full"></div>
                  ) : (
                    <div className="w-1.5 h-1.5 bg-[#767D88] rounded-full"></div>
                  )}
                  <p className="text-sm">{subroute?.name}</p>
                </li>
              </ul>
            </div>
          ))}
        </div>
      )}
      <CustomContactUs isOpen={modalOpen} onClose={closeModal} />
    </li>
  );
};
