import React, { useState } from "react";
import { Text } from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";

const HomeMessageList = ({
  messages,
  data,
  isEditing,
  setIsEditing,
  openModal,
}) => {
  return (
    <div className="flex flex-col w-full space-y-4 py-2 h-full px-4">
      {messages?.length === 0 && openModal && (
        <div className="flex items-center justify-center h-full w-full"></div>
      )}

      {messages?.length === 0 && !openModal ? (
        <div className="flex justify-center items-center h-full w-full">
          <div className="flex flex-col space-y-4 items-center">
            <p className="text-2xl font-medium font-poppins text-center">
              Welcome To Chatbot
            </p>
            <p className="text-base font-poppins text-center">
              Your Virtual Assistant For Seamless Interactions
            </p>
          </div>
        </div>
      ) : (
        messages?.map((message, index) => (
          <>
            <div
              key={index}
              className="bg-blue-500 mt-2 text-white rounded-lg p-3  max-w-xs self-end"
            >
              {message?.query?.name || message?.query}
            </div>
            <div
              key={index + "_response"}
              className="bg-gray-200  flex justify-start text-gray-800 rounded-lg p-3 max-w-sm"
            >
              {message?.response ? (
                <Text as="p" whiteSpace="pre-line" className="w-full">
                  <p className="w-full">{message?.response}</p>
                </Text>
              ) : (
                <div className="flex gap-2">
                  <div className="w-2 h-2 rounded-full animate-pulse bg-blue-600"></div>
                  <div className="w-2 h-2 rounded-full animate-pulse bg-blue-600"></div>
                  <div className="w-2 h-2 rounded-full animate-pulse bg-blue-600"></div>
                </div>
              )}
            </div>
          </>
        ))
      )}

      <ToastContainer />
    </div>
  );
};

export default HomeMessageList;
