import { useEffect, useRef } from "react";

export const useWithSound = (audioSource) => {
    const soundRef = useRef(null);

    const playSound = () => {
        if (soundRef.current) {
            soundRef.current.play().catch((error) => {
                console.error("Error playing sound:", error);
                // Optional: Implement fallback logic or notify the user.
            });
        }
    };

    const pauseSound = () => {
        if (soundRef.current) {
            soundRef.current.pause();
        }
    };

    useEffect(() => {
        soundRef.current = new Audio(audioSource);
        soundRef.current.preload = "auto"; // Preload the audio

        return () => {
            if (soundRef.current) {
                soundRef.current.pause();
                soundRef.current = null;
            }
        };
    }, [audioSource]);

    return {
        playSound,
        pauseSound,
    };
};
