import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  ListItem,
  Button,
  Flex,
  UnorderedList,
  Card,
  CardBody,
  CardFooter,
  Center,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  basic_yearly,
  expanded_yearly,
  basic_monthly,
  expanded_monthly,
} from "../utils/config";

const Pricing = ({ showTitle, handleClick, widthNew }) => {
  const [isActiveMonthlyPlan, setIsActiveMonthlyPlan] = useState(true);
  const [isActiveYearlyPlan, setIsActiveYearlyPlan] = useState(false);

  const navigate = useNavigate();

  const handleDemoClick = () => {
    navigate("/contactus");
  };

  const userold = useSelector((state) => state.user);
  const currentUser = userold?.currentUser;
  const currentEmployee = currentUser?.employees[userold?.currentCompany];
  const roleName = currentEmployee?.rolename;

  const divStyle = {
    // width: widthNew ? "250px" : "450px",
    padding: "0px",
  };

  const pricingDatamonthly = [
    {
      title: "Basic Plan",
      heading: "Simple. Transparent. Effective.",
      name: "Establish a strong foundation for goal setting and performance management:",
      price_id: basic_monthly,
      price: "$5",
      features: [
        "Goals & OKRs: Create, clone, and track progress with relevant KPIs",
        "Drive team engagement and focus: Schedule weekly, monthly, and quarterly Touch Bases with your team members. ",
        "Set up your organization’s hierarchy: A flexible and adaptable platform to support your business structure.",
        "Team Engagement (Tag members)",
        "Visualization Tools: Alignment and cascading objectives views for clarity.",
        "Reminders & Notifications: Stay organized and on schedule.",
        "Collaboration Features: Tag team members, search, and filter data.",
        "Insights & Integrations: Reporting, analytics, and seamless tool connections.",
        "Exportable Reports (PPT/PDF): Create professional-grade reports for presentations and analysis.",
        "Up To 5 Team members",
      ],
    },
    {
      title: "Expanded Plan",
      heading: "Customizable. Scalable. Intelligent.",
      name: "Enhance your team’s performance tracking and operational insights with advanced features:",
      description: "Includes all features from the Basic Plan, PLUS:",
      price_id: expanded_monthly,
      price: "$10",
      features: [
        "Custom Data Integrations: Adapt tools to suit your organization’s needs.",
        "AI-Assisted Goal Creation: Streamline the OKR setting process with meaningful, measurable objectives leveraging AI to suggest relevant, data-driven goals.",
        "AI-Supported Continuous Improvement: Save time, and enhance alignment and focus by applying learnings from historical data and current priorities to recommend OKRs.",
        "AI-Generated Executive Summaries: Distill complex OKR data into concise, actionable summaries, enabling executives to make informed decisions faster with minimal effort.",
        "Standard Support: Access timely assistance from our support team.",
        "SSO Integration: Enhance security and simplify access with single sign-on.",
        "Up To 25 team members",
      ],
    },
    {
      title: "Premium Plan",
      heading:
        "Comprehensive. Flexible. Enterprise-Ready.Contact us for pricing",
      name: "Equip your organization with the ultimate tools and personalized support for success at scale:",
      description: "Includes all features from the Expanded Plan, PLUS:",
      // price: "$120",
      features: [
        "Dedicated Account Manager: Receive tailored guidance and support.",
        "Professional Coaching Services: Empower teams with expert-led OKR foundational and expert-level training.",
        "Team Limit: 100+ members.",
      ],
      footer:
        "The Premium Plan is designed to empower organizations with enterprise-grade tools, insights, and personalized support to drive unparalleled success.",
      demoButton: true,
    },
  ];

  const pricingDataAnually = [
    {
      title: "Basic Plan",
      heading: "Simple. Transparent. Effective.",
      name: "Establish a strong foundation for goal setting and performance management:",
      price_id: basic_yearly,
      price: "$48",
      features: [
        "Goals & OKRs: Create, clone, and track progress with relevant KPIs",
        "Drive team engagement and focus: Schedule weekly, monthly, and quarterly Touch Bases with your team members. ",
        "Set up your organization’s hierarchy: A flexible and adaptable platform to support your business structure.",
        "Team Engagement (Tag members)",
        "Visualization Tools: Alignment and cascading objectives views for clarity.",
        "Reminders & Notifications: Stay organized and on schedule.",
        "Collaboration Features: Tag team members, search, and filter data.",
        "Insights & Integrations: Reporting, analytics, and seamless tool connections.",
        "Exportable Reports (PPT/PDF): Create professional-grade reports for presentations and analysis.",
        "Up To 5 Team members",
      ],
    },
    {
      title: "Expanded Plan",
      heading: "Customizable. Scalable. Intelligent.",
      name: "Enhance your team’s performance tracking and operational insights with advanced features:",
      description: "Includes all features from the Basic Plan, PLUS:",
      price_id: expanded_yearly,
      price: "$96",
      features: [
        "Custom Data Integrations: Adapt tools to suit your organization’s needs.",
        "AI-Assisted Goal Creation: Streamline the OKR setting process with meaningful, measurable objectives leveraging AI to suggest relevant, data-driven goals.",
        "AI-Supported Continuous Improvement: Save time, and enhance alignment and focus by applying learnings from historical data and current priorities to recommend OKRs.",
        "AI-Generated Executive Summaries: Distill complex OKR data into concise, actionable summaries, enabling executives to make informed decisions faster with minimal effort.",
        "Standard Support: Access timely assistance from our support team.",
        "SSO Integration: Enhance security and simplify access with single sign-on.",
        "Up To 25 team members",
      ],
    },
    {
      title: "Premium Plan",
      heading:
        "Comprehensive. Flexible. Enterprise-Ready.Contact us for pricing",
      name: "Equip your organization with the ultimate tools and personalized support for success at scale:",
      description: "Includes all features from the Expanded Plan, PLUS:",
      // price: "$120",
      features: [
        "Dedicated Account Manager: Receive tailored guidance and support.",
        "Professional Coaching Services: Empower teams with expert-led OKR foundational and expert-level training.",
        "Team Limit: 100+ members.",
      ],
      demoButton: true,
      footer:
        "The Premium Plan is designed to empower organizations with enterprise-grade tools, insights, and personalized support to drive unparalleled success.",
    },
  ];

  return (
    <Box id="pricing" className="md:px-3 py-10 overflow-x-hidden">
      {showTitle && (
        <Heading
          as="h2"
          fontSize={{ base: "2xl", md: "4xl" }}
          color={"#14285a"}
          textAlign={"center"}
        >
          iMongu Plans
        </Heading>
      )}
      <Box display={"flex"} justifyContent={"center"} mb={"5"} mt={3}>
        <Flex
          borderRadius={"full"}
          bg={"#ECECEC"}
          p={"8px 10px"}
          justifyContent={"center"}
          gap={2}
        >
          <Box
            borderRadius={"full"}
            bg={isActiveMonthlyPlan ? "#F27E0D" : "#ECECEC"}
            p={"8px 25px"}
            cursor={"pointer"}
            black={"#212121"}
            onClick={() => {
              setIsActiveMonthlyPlan(true);
              setIsActiveYearlyPlan(false);
            }}
          >
            <Text color={isActiveMonthlyPlan ? "#FFFFFF" : "#000000"}>
              Monthly
            </Text>
          </Box>
          <Box
            borderRadius={"full"}
            bg={isActiveYearlyPlan ? "#F27E0D" : "#ECECEC"}
            p={"8px 25px"}
            cursor={"pointer"}
            onClick={() => {
              setIsActiveYearlyPlan(true);
              setIsActiveMonthlyPlan(false);
            }}
          >
            <Text color={isActiveYearlyPlan ? "#FFFFFF" : "#000000"}>
              Yearly
            </Text>
          </Box>
        </Flex>
      </Box>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
        {isActiveMonthlyPlan &&
          pricingDatamonthly?.map((plan, index) => (
            <PricingCard
              key={index}
              title={plan.title}
              divStyle={divStyle}
              heading={plan.heading}
              name={plan.name}
              price={plan.price}
              features={plan.features}
              description={plan.description}
              demoButton={plan.demoButton}
              footer={plan.footer}
              handleDemoClick={handleDemoClick}
              planType="monthly"
              showTitle={showTitle}
              handleClick={() => {
                handleClick(plan);
              }}
              roleName={roleName}
            />
          ))}

        {isActiveYearlyPlan &&
          pricingDataAnually?.map((plan, index) => (
            <PricingCard
              key={index}
              title={plan.title}
              divStyle={divStyle}
              heading={plan.heading}
              name={plan.name}
              price={plan.price}
              features={plan.features}
              description={plan.description}
              footer={plan.footer}
              demoButton={plan.demoButton}
              handleDemoClick={handleDemoClick}
              planType="yearly"
              showTitle={showTitle}
              handleClick={() => {
                handleClick(plan);
              }}
              roleName={roleName}
            />
          ))}
      </SimpleGrid>
    </Box>
  );
};

const PricingCard = ({
  title,
  heading,
  name,
  price,
  features,
  planType,
  demoButton,
  footer,
  description,
  handleDemoClick,
  showTitle,
  handleClick,
  divStyle,
  roleName,
}) => (
  <div className="md:px-6  py-10 flex justify-center " style={divStyle}>
    <Card className="shadow-2xl w-4/5 flex justify-center md:w-11/12 md:h-full p-2 transition-transform transform-gpu hover:scale-105 hover:shadow-md ">
      <CardBody>
        <Heading as="h3" fontSize="3xl" mb={4} textAlign={"center"}>
          {title}
        </Heading>

        <Text as="h5" fontWeight={"Bold"} fontSize="xl" textAlign={"center"}>
          {heading}
        </Text>
        <Text
          as="h5"
          fontSize="lg"
          mb={4}
          mt={4}
          textAlign={"center"}
          color={"#F7AD18"}
        >
          {name}
        </Text>
        <Text
          as="h8"
          fontSize="sm"
          mb={4}
          mt={4}
          textAlign={"center"}
          fontWeight={"bold"}
          // color={"#F7AD18"}
        >
          {description}
        </Text>
        {!demoButton && (
          <Text
            fontSize="4xl"
            fontWeight="bold"
            mb={4}
            textAlign={"center"}
            color={"#F7AD18"}
          >
            {price}
            <Flex direction="column" align="center" justify="center">
              <Text as="span" color="gray.500" fontSize="md">
                User/{planType === "monthly" ? "Month" : "Year"}
              </Text>
            </Flex>
          </Text>
        )}
        <UnorderedList className="mb-2">
          {features?.map((feature, index) => {
            const [title, description] = feature.split(":");
            return (
              <ListItem key={index} fontSize="md" mt={2} color="gray.500">
                <Text as="span" fontWeight="bold">
                  {title}
                  {description && ":"}
                </Text>{" "}
                <Text className="text-md">
                {description?.trim()}
                </Text>
              </ListItem>
            );
          })}
        </UnorderedList>
        <Text  color="gray.500" className="mt-4 text-md ">
          {footer}
        </Text>
      </CardBody>
      {roleName === "Admin" && (
        <CardFooter
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {!demoButton ? (
            <>
              {showTitle ? (
                <Link to="/Register">
                  <Button
                    colorScheme="#F27E0D"
                    w="full"
                    px={6}
                    borderRadius="20px"
                    bgColor="#F27E0D"
                    className="hover:bg-button-hover transition-all"
                  >
                    Subscribe
                  </Button>
                </Link>
              ) : (
                <Box>
                  <Button
                    colorScheme="#F27E0D"
                    w="full"
                    px={6}
                    borderRadius="20px"
                    bgColor="#F27E0D"
                    className="hover:bg-button-hover transition-all"
                    onClick={handleClick}
                  >
                    Subscribe
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <Box>
              <Button
                colorScheme="#F27E0D"
                w="full"
                px={6}
                borderRadius="20px"
                bgColor="#F27E0D"
                className="hover:bg-button-hover transition-all"
                onClick={handleDemoClick}
              >
                Book A Demo <ArrowForwardIcon className="ml-1" />
              </Button>
            </Box>
          )}
        </CardFooter>
      )}
    </Card>
  </div>
);

export default Pricing;
